var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stepsProgressBarContainer hidden-md-and-down"},[_c('div',{staticClass:"stepsProgressBar"},[_c('div',{class:[
        'step',
        'rounded-left-corners',
        { 'done-step': _vm.step > 1 },
        { 'current-step': _vm.step === 1 }
      ]},[_vm._v(" Step 1 of 4 ")]),_c('div',{class:[
        'step',
        { 'done-step': _vm.step > 2 },
        { 'current-step': _vm.step === 2 }
      ]},[_vm._v(" Step 2 of 4 ")]),_c('div',{class:[
        'step',
        { 'done-step': _vm.step > 3 },
        { 'current-step': _vm.step === 3 }
      ]},[_vm._v(" Step 3 of 4 ")]),_c('div',{class:[
        'step',
        'rounded-right-corners',
        { 'current-step': _vm.step === 4 }
      ]},[_vm._v(" Step 4 of 4 ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }